.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.csv-input::-webkit-file-upload-button {
  margin-right: 20px;
  border: none;
  background: #20c77c;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.csv-input,
input[type="file"] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}
@media print {
  .print-no {
    display: none; /* hide whole page */
  }

  td,
  th {
    border: 0.3;
    text-align: left;
    padding: 8px;
  }
}

@media screen and (max-width: 600px){
    .tableresponsive{
        overflow: scroll;
        height: 400;
    }
}

@media (max-width: 1000px) {
  .ant-picker-time-panel{
    width: 100;
  }
    .ant-calendar-range {
        width: 320px;
    }
    .ant-calendar-range-part {
        width: 100%;
    }
}
/*
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
*/
